<script lang="ts" setup>
/*
  Fixme: the hardcoded ".hu" keys are not good here. Either API would give them back already localized, or we need to use an .env variable for those.
*/

import { usePriceFormatter } from "~/utils/priceFormatter";

const props = defineProps<{
  index: number;
  order: Record<string, any>;
}>();

const { getAssetUrl } = useHelpers();
const { t } = useI18n();

const hiddenRef = ref(true);

const { data: shippingMethodsRef } = useApiFetch("shipping-methods");

const itemImage = (path) => {
  if (path) {
    return getAssetUrl(path);
  }
};

const foxpostAddress = () => {
  const data = JSON.parse(props.order.shipping_external_data);
  return data?.address;
};

const glsPointAddress = () => {
  const data = JSON.parse(props.order.shipping_external_data);
  // console.log(data);
  return data?.name;
};
/*
onMounted(() => {
  console.log(props.order);
});
*/
</script>
<template>
  <div class="shadow-md mb-3">
    <div
      class="flex flex-row cursor-pointer p-5"
      @mousedown.prevent="hiddenRef = !hiddenRef"
    >
      <div class="basis-5/6 font-bold text flex flex-row">
        <span
          >{{ props.index }}. {{ t("profile.order_number") }}:
          {{ props.order.number }}
        </span>
      </div>
      <div class="basis-1/6 text-right">
        <CheckoutLargeArrow :inverted="hiddenRef" />
      </div>
    </div>
    <div :class="{ hidden: hiddenRef }" class="px-3 pb-3">
      <!-- header -->
      <div class="grid grid-cols-2 md:grid-cols-3 gap-4 border-b pb-5">
        <div class="text-center">
          <div class="text-xs font-medium">
            {{ t("profile.order_created_at") }}
          </div>
          <div class="text-sm">
            {{ useDateFormatter(props.order.created_at) }}
          </div>
        </div>

        <div class="text-center">
          <div class="text-xs font-medium">
            {{ t("profile.name") }}
          </div>
          <div class="text-sm">
            {{ props.order.family_name }} {{ props.order.given_name }}
          </div>
        </div>

        <div class="text-center">
          <div class="text-xs font-medium">
            {{ t("profile.shipping_address") }}
          </div>
          <div class="text-sm">
            <template v-if="props.order.shipping_method_id === 'gls'">
              {{ props.order.shipping_address.postcode.code }}
              {{ props.order.shipping_address.city.name.hu }},
              {{ props.order.shipping_address.street_txt }}
              {{ props.order.shipping_address.street_type.name.hu }}
              {{ props.order.shipping_address.street_number }}
            </template>
            <template v-if="props.order.shipping_method_id === 'mpl'">
              {{ props.order.shipping_address.postcode.code }}
              {{ props.order.shipping_address.city.name.hu }},
              {{ props.order.shipping_address.street_txt }}
              {{ props.order.shipping_address.street_type.name.hu }}
              {{ props.order.shipping_address.street_number }}
            </template>
            <template v-if="props.order.shipping_method_id === 'personal'">
              {{
                shippingMethodsRef?.find((item) => item.id === "personal")
                  ?.name
              }}<br />
              {{ props.order.shipping_store.name.hu }}
            </template>
            <template v-if="props.order.shipping_method_id === 'gls_point'">
              {{
                shippingMethodsRef?.find((item) => item.id === "gls_point")
                  ?.name
              }}<br />
              {{ glsPointAddress() }}
            </template>
            <template v-if="props.order.shipping_method_id === 'foxpost'">
              {{
                shippingMethodsRef?.find((item) => item.id === "foxpost")?.name
              }}<br />
              {{ foxpostAddress() }}
            </template>
          </div>
        </div>

        <div class="text-center">
          <div class="text-xs font-medium">
            {{ t("profile.order_total") }}
          </div>
          <div class="text-sm">
            {{ usePriceFormatter(props.order._prices.total) }}
          </div>
        </div>

        <div class="text-center">
          <div class="text-xs font-medium">
            {{ t("email") }}
          </div>
          <div class="text-sm">
            {{ props.order.email }}
          </div>
        </div>

        <div class="text-center">
          <div class="text-xs font-medium">
            {{ t("profile.billing_address") }}
          </div>
          <div class="text-sm">
            {{ props.order.billing_address.postcode.code }}
            {{ props.order.billing_address.city.name.hu }},
            {{ props.order.billing_address.street_txt }}
            {{ props.order.billing_address.street_type.name.hu }}
            {{ props.order.billing_address.street_number }}
          </div>
        </div>

        <div class="text-center">
          <div class="text-xs font-medium">
            {{ t("profile.order_status") }}
          </div>
          <div class="text-sm">
            {{ t("profile.order_statuses." + props.order.status) }}
          </div>
        </div>

        <div class="text-center">
          <div class="text-xs font-medium">
            {{ t("phone") }}
          </div>
          <div class="text-sm">
            {{ props.order.phone }}
          </div>
        </div>

        <div class="text-center">
          <div class="text-xs font-medium">
            {{ t("checkout.payment_method") }}
          </div>
          <div class="text-sm">
            {{ props.order.payment_method.name.hu }}
          </div>
        </div>
      </div>

      <!-- items -->
      <div
        v-for="item in props.order.items"
        :key="item.id"
        class="flex flex-row items-center text-xs mt-3"
      >
        <div class="basis-1/6 px-1">
          <NuxtImg
            v-if="Array.isArray(item._images) && item._images.length"
            :src="itemImage(item._images[0].path)"
            format="webp"
            loading="lazy"
            width="150"
          />
        </div>
        <div class="basis-2/6 px-1">
          {{ item._masterProduct.name.hu }}
        </div>
        <div class="md:basis-1/6 px-1">
          {{ item._product.product_meret_property }}
        </div>
        <div class="basis-1/6 px-1">{{ item.quantity }} db.</div>
        <div class="basis-1/6 px-1">
          {{ usePriceFormatter(item.price) }}
        </div>
      </div>
    </div>
  </div>
</template>
