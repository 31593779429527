<script lang="ts" setup>
import { useMeilisearch, useMeilisearchIndex } from "~/utils/meilisearch";

const config = useRuntimeConfig();
const { t } = useI18n();

const props = defineProps<{
  mode: string;
  type: string;
  address?: any;
  cancelView?: string | null;
  makeDefaultTitle?: string | null;
}>();

const emit = defineEmits(["formSubmit", "changeView"]);

const postcodeSearch = ref("");
const postcodeOptions = ref();
const cityOptions = ref();
const streetTypeOptions = ref();
const toggleCityOptionsIsOpen = ref(0);

const addressId = ref();
const name = ref();
const postcode = ref();
const city = ref();
const street = ref();
const streetType = ref();
const streetNo = ref();
const building = ref();
const staircase = ref();
const floor = ref();
const door = ref();
const makeDefault = ref(props.address?.pivot.is_default);

const nameError = ref();
const postcodeError = ref();
const cityError = ref();
const streetError = ref();
const streetTypeError = ref();

const getCityOptions = async (
  postcodeId: string | number | null | undefined,
) => {
  const options = [];
  if (postcodeId) {
    const postcodes = await useMeilisearch(
      config.public.meilisearchIndexPostcodes,
      {
        filter: ["id = " + postcodeId],
      },
    );
    // console.log("postcodes", postcodes);
    if (postcodes.length === 1) {
      for (const city of postcodes[0].cities) {
        options.push({ id: city.id, name: city.name });
      }
    }
  }

  // console.log("options", options);
  return options;
};

const getPostcodeOptions = async (
  currentId: string | number | null | undefined,
  searchTerm: string | null | undefined,
) => {
  const options = [];
  if (currentId) {
    const postcodes = await useMeilisearch(
      config.public.meilisearchIndexPostcodes,
      {
        filter: ["id = " + currentId],
      },
    );
    // console.log("postcodes", postcodes);
    if (postcodes.length === 1) {
      options.push({ id: postcodes[0].id, name: postcodes[0].code });
    }
  }

  if (searchTerm && searchTerm.length > 1) {
    const index = useMeilisearchIndex(config.public.meilisearchIndexPostcodes);
    if (index) {
      const query = await index.search(searchTerm, {
        attributesToSearchOn: ["code"],
      });
      if (query.hits) {
        // console.log("hits", query.hits);
        for (const hit of query.hits) {
          const objToAdd = { id: hit.id, name: hit.code };

          const isObjectInArray = options.some((obj) => {
            // Compare objects by checking if their properties match
            return obj.id === objToAdd.id;
          });
          if (!isObjectInArray) {
            options.push(objToAdd);
          }
        }
      }
    }
  }

  // console.log("options", options);
  return options;
};

const getStreetTypeOptions = async () => {
  const options = [];

  const streettypes = await useMeilisearch(
    config.public.meilisearchIndexStreetTypes,
    { limit: 200 },
  );
  for (const streettype of streettypes) {
    const objToAdd = { id: streettype.id, name: streettype.name };
    options.push(objToAdd);
  }

  // console.log("options", options);
  return options;
};

// -----------------------------------
// postcode + city
// -----------------------------------
const updatePostcodeOptions = async () => {
  const options = await getPostcodeOptions(
    postcode.value,
    postcodeSearch.value,
  );

  postcodeOptions.value = options;
  // await nextTick();
};

const updateCityOptions = async () => {
  cityOptions.value = await getCityOptions(postcode.value);
  if (cityOptions.value.length > 1 && !city.value) {
    toggleCityOptionsIsOpen.value = Date.now();
  }
};

const handlePostcodeSearch = (search: string) => {
  // console.log("searching: " + search);
  postcodeSearch.value = search;
};

watch(
  () => postcodeSearch.value,
  () => {
    updatePostcodeOptions();
  },
);

watch(
  () => postcode.value,
  async (newValue, oldValue) => {
    if (String(newValue) !== String(oldValue)) {
      city.value = null;
      await updateCityOptions();
    }
  },
);

const initSelectAutocompletes = () => {
  updatePostcodeOptions();
  updateCityOptions();
};

// -----------------------
// methods
// -----------------------
const validateForm = () => {
  let errorHappened = false;

  nameError.value = "";
  postcodeError.value = "";
  cityError.value = "";
  streetError.value = "";
  streetTypeError.value = "";

  if (!name.value) {
    nameError.value = "checkout.errors.address_name";
    errorHappened = true;
  }
  if (!postcode.value) {
    postcodeError.value = "checkout.errors.shipping_postcode";
    errorHappened = true;
  }
  if (!city.value) {
    cityError.value = "checkout.errors.shipping_city";
    errorHappened = true;
  }
  if (!street.value) {
    streetError.value = "checkout.errors.shipping_street";
    errorHappened = true;
  }
  if (!streetType.value) {
    streetTypeError.value = "checkout.errors.shipping_street_type";
    errorHappened = true;
  }

  return !errorHappened;
};

const submitForm = () => {
  const valid = validateForm();
  if (valid) {
    const formData = {
      id: addressId.value,
      type: props.type,
      country_id: "hu",
      name: name.value,
      postcode: postcode.value,
      city: city.value,
      street_txt: street.value,
      street_type: streetType.value,
      street_number: streetNo.value,
      building: building.value,
      staircase: staircase.value,
      floor: floor.value,
      door: door.value,
      make_default: makeDefault.value,
    };
    emit("formSubmit", formData);
  }
};

// -----------------------
// vue events
// -----------------------
onMounted(async () => {
  const options = await getStreetTypeOptions();
  streetTypeOptions.value = options;

  if (props.mode === "edit") {
    // console.log(props.address);
    addressId.value = props.address.id;
    name.value = props.address.name;
    postcode.value = props.address.postcode_id;
    city.value = props.address.city_id;
    street.value = props.address.street_txt;
    streetType.value = props.address.street_type_id;
    streetNo.value = props.address.street_number;
    building.value = props.address.building;
    staircase.value = props.address.staircase;
    floor.value = props.address.floor;
    door.value = props.address.door;
  }

  initSelectAutocompletes();
});
</script>
<template>
  <div>
    <div class="w-full py-3">
      <div class="mb-4 md:flex">
        <div class="w-full">
          <BaseInput
            v-model="name"
            :title="t('profile.new_address_name')"
            :mandatory="true"
            :error="nameError"
          />
        </div>
      </div>

      <div class="mb-4 md:flex md:justify-between">
        <div class="w-full mb-4 md:mb-0 md:w-1/3 md:pr-2">
          <BaseSelectAutocomplete
            v-model="postcode"
            :error="postcodeError"
            :source="postcodeOptions"
            :title="t('postcode')"
            :mandatory="true"
            @search="handlePostcodeSearch"
          />
        </div>
        <div class="w-full md:w-2/3 md:pl-2">
          <BaseSelectAutocomplete
            v-model="city"
            :error="cityError"
            :source="cityOptions"
            :title="t('city')"
            :mandatory="true"
            :searchable="false"
            :disabled="postcode === null"
            :disabled-info="t('checkout.please_select_postcode_first')"
            :toggle-is-open="toggleCityOptionsIsOpen"
          />
        </div>
      </div>

      <div class="mb-4 md:flex md:justify-between">
        <div class="w-full mb-4 md:mb-0 md:w-1/2 md:pr-2">
          <BaseInput
            v-model="street"
            :error="streetError"
            :title="t('checkout.street')"
            :mandatory="true"
          />
        </div>
        <div class="w-full md:w-1/2 md:pl-2">
          <BaseSelectAutocomplete
            v-model="streetType"
            :error="streetTypeError"
            :source="streetTypeOptions"
            :title="t('checkout.street_type')"
            :mandatory="true"
            :show-all-options-when-no-search="true"
          />
        </div>
      </div>

      <div class="mb-4 md:flex md:justify-between">
        <div class="w-full mb-4 md:mb-0 md:w-1/5 md:pr-2">
          <BaseInput v-model="streetNo" :title="t('checkout.street_no')" />
        </div>
        <div class="w-full md:w-1/5 md:pr-2">
          <BaseInput v-model="building" :title="t('checkout.building')" />
        </div>
        <div class="w-full md:w-1/5 md:pr-2">
          <BaseInput v-model="staircase" :title="t('checkout.staircase')" />
        </div>
        <div class="w-full md:w-1/5 md:pr-2">
          <BaseInput v-model="floor" :title="t('checkout.floor')" />
        </div>
        <div class="w-full md:w-1/5">
          <BaseInput v-model="door" :title="t('checkout.door')" />
        </div>
      </div>

      <div v-if="props.makeDefaultTitle" class="mb-4 md:flex">
        <BaseCheckbox2Way
          v-model="makeDefault"
          :label="t(props.makeDefaultTitle)"
        />
      </div>
    </div>

    <div class="flex flex-row">
      <BaseButton type="primary" class="mr-4" @click="submitForm"
        >{{ t("save") }}
      </BaseButton>
      <BaseButton
        v-if="props.cancelView"
        type="secondary"
        @mousedown.prevent="emit('changeView', 'cancelView')"
        >{{ t("cancel") }}
      </BaseButton>
    </div>
  </div>
</template>
