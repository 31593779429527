<script lang="ts" setup>
const { status, signIn } = useAuth();
const { t } = useI18n();

// -----------------------
// vue events
// -----------------------
/*
onMounted(() => {
  console.log(status.value);
  console.log(data.value);
});
*/

const redirectToRegistration = async () => {
  const registrationUrl = await $fetch(`/api/keycloak/registrationUrl`);
  if (registrationUrl) window.location.href = registrationUrl;
};
</script>

<template>
  <div v-if="status === 'unauthenticated'" class="container">
    <div class="flex flex-wrap">
      <div
        class="w-full md:w-1/2 border-b md:border-r md:border-b-0 border-surface00 pb-5 mb-5 md:pb-0 md:mb-0"
      >
        <h1 class="text-xl font-bold mb-10 text-center">
          {{ t("profile.login") }}
        </h1>
        <div class="md:h-64 flex flex-col items-center">
          <i class="m-icon-login-lock inline-block text-[64px]">
            <span class="path1"></span>
            <span class="path2"></span>
          </i>
          <div class="text-14 p-5 text-center">
            {{ t("profile.login_hint") }}
          </div>
        </div>
        <div class="text-center">
          <BaseButton
            id="keycloak-login"
            type="primary"
            @click="signIn('keycloak')"
            >{{ t("profile.login") }}</BaseButton
          >
        </div>
      </div>
      <div class="w-full md:w-1/2">
        <h1 class="text-xl font-bold mb-10 text-center">
          {{ t("profile.register") }}
        </h1>
        <div class="md:h-64 flex flex-col items-center">
          <i class="m-icon-registration inline-block text-[64px]">
            <span class="path1"></span>
            <span class="path2"></span>
          </i>
          <div class="m-5 text-14">
            <ul class="list-disc custom-list-marker">
              <li>
                <div class="flex">
                  <div class="w-1/12">
                    <i class="m-icon-check text-10 mt-[3px] ml-[4px]"></i>
                  </div>
                  <div class="w-11/12">
                    <b>{{ t("profile.register_hint_1_title") }}</b
                    ><br />
                    {{ t("profile.register_hint_1_text") }}
                  </div>
                </div>
              </li>
              <li>
                <div class="flex">
                  <div class="w-1/12">
                    <i class="m-icon-check text-10 mt-[3px] ml-[4px]"></i>
                  </div>
                  <div class="w-11/12">
                    <b>{{ t("profile.register_hint_2_title") }}</b
                    ><br />
                    {{ t("profile.register_hint_2_text") }}
                  </div>
                </div>
              </li>
              <!--
              <li>
                <div class="flex">
                  <div class="w-1/12">
                    <i class="m-icon-check text-10 mt-[3px] ml-[4px]"></i>
                  </div>
                  <div class="w-11/12">
                    <b>{{ t("profile.register_hint_3_title") }}</b>
                  </div>
                </div>
              </li>
              -->
            </ul>
          </div>
        </div>
        <div class="text-center">
          <BaseButton type="primary" @click="redirectToRegistration">{{
            t("profile.register")
          }}</BaseButton>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
ul.custom-list-marker li {
  list-style: none;
  padding-left: 0;
  margin-bottom: 15px;
}
</style>
