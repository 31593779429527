<script lang="ts" setup>
const emit = defineEmits(["changeView"]);
const { $api } = useNuxtApp();
const { t } = useI18n();

const saveAddress = (formData: any) => {
  $api("user/my-address", {
    method: "POST",
    body: formData,
  })
    .then(() => {
      emit("changeView", "billingData");
    })
    .catch(() => {
      // TODO: show an informative error message to user
    });
};
</script>
<template>
  <div>
    <h1 class="text-3xl font-bold mb-10">
      {{ t("profile.new_billing_address") }}
    </h1>

    <ProfileAddressForm
      mode="create"
      make-default-title="profile.make_default_billing_address"
      type="billing"
      @form-submit="saveAddress"
    />
  </div>
</template>
