<script lang="ts" setup>
const { data: authData } = useAuth();
const { t } = useI18n();

defineEmits(["changeView"]);

const {
  data: addressesRef,
  refresh: refreshAddressesRef,
  error: addressErrorRef,
} = useApiFetch("user/my-addresses");

watch(
  () => addressErrorRef.value,
  () => {
    showMaintenanceView();
  },
);

const filteredAddresses = computed(() => {
  if (addressesRef.value) {
    return addressesRef.value.filter(
      (address: Record<string, any>) => address.type === "shipping",
    );
  }
  return [];
});
</script>
<template>
  <div v-if="authData?.user">
    <h2 class="text-3xl font-bold mb-5">
      {{ t("profile.shipping_data") }}
    </h2>

    <div v-if="filteredAddresses.length" class="mb-5">
      <div v-for="(address, index) in filteredAddresses" :key="address.id">
        <ProfileAddressPanel
          :address="address"
          :index="index"
          make-default-title="profile.make_default_shipping_address"
          type="shipping"
          @address-changed="refreshAddressesRef"
        />
      </div>
    </div>

    <BaseButton
      type="primary"
      class="mb-5"
      @mousedown.prevent="$emit('changeView', 'newShippingAddress')"
      >{{ t("profile.add_new_address") }}</BaseButton
    >
  </div>
</template>
